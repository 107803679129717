<template>
  <v-form
    ref="regrasForm"
    dense
    lazy-validation
  >
    <v-card
      outlined
      class="mb-2"
    >
      <v-tabs
        v-model="tabs"
        color="success"
      >
        <v-tab>
          {{ indexRegra + 1 }}º Regra
        </v-tab>
        <v-tab>
          Produtos
        </v-tab>
        <v-tab>
          Bonificações
        </v-tab>
        <v-tab-item>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="regra.nome"
                  dense
                  autocomplete="off"
                  :rules="[rules.required]"
                  label="Descrição que aparecerá na tag da loja*"
                  validate-on-blur
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                v-if="tipo === 'campanha'"
                cols="12"
                md="3"
                class="pr-0"
              >
                <v-radio-group
                  v-model="regra.tipo"
                  row
                  mandatory
                  hide-details
                  default="quantidade"
                  class="justify-center mt-1"
                >
                  <v-radio
                    label="Unidade"
                    value="quantidade"
                  />
                  <v-radio
                    label="Volume"
                    value="volume"
                  />
                  <v-icon
                    class="mt-0 pt-0"
                    color="primary"
                    size="20"
                    @click="information(regra.tipo)"
                  >
                    mdi-comment-question-outline
                  </v-icon>
                </v-radio-group>
              </v-col>

              <v-col
                v-if="regra.tipo === 'volume'"
                cols="12"
                md="2"
              >
                <v-autocomplete
                  v-model="regra.unidade"
                  :items="unidadesMedida"
                  item-text="nome"
                  item-value="id"
                  :rules="[rules.required]"
                  class="d-flex justify-space-between"
                  label="Unidade de medida*"
                  outlined
                  rounded
                  dense
                  @change="unidadeChange()"
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-container
                      class="rounded-lg"
                      fluid
                    >
                      <v-row
                        dense
                        justify="space-between"
                      >
                        <div>{{ data.item.nome }}</div>
                      </v-row>
                    </v-container>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="regra.tipo === 'volume' && regra.tipo !== 'oferta'"
                cols="1"
                class="d-flex"
              >
                <v-btn
                  class="text-none text-white mb-3"
                  small
                  title="Incluir uma nova condição na regra."
                  color="success"
                  rounded
                  @click="NEW_CONDICAO(regra)"
                >
                  <v-icon
                    class="mr-1"
                    small
                  >
                    mdi-file-plus
                  </v-icon>
                  Nova condição
                </v-btn>
              </v-col>
            </v-row>

            <v-card
              v-if="regra.tipo === 'quantidade' || tipo === 'oferta'"
              outlined
              style="border-radius: 12px"
            >
              <v-container
                fluid
                class="mt-5"
              >
                <v-row>
                  <v-col
                    v-if="tipo === 'campanha'"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="regra.condicoes[0].quantidade"
                      dense
                      autocomplete="off"
                      :rules="[rules.required, rules.gt(0)]"
                      label="Quantidade* (em unidades)"
                      minlength="1"
                      validate-on-blur
                      type="number"
                      step="1"
                      outlined
                      rounded
                    />
                  </v-col>
                  <v-col
                    v-if="tipo === 'campanha'"
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="regra.condicoes[0].calculo"
                      dense
                      :items="itemsCalculo"
                      :rules="[rules.required]"
                      label="Calcular regra por:"
                      item-value="value"
                      item-text="text"
                      validate-on-blur
                      outlined
                      rounded
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="regra.condicoes[0].desconto"
                      v-mask="'##.##'"
                      dense
                      autocomplete="off"
                      :rules="[rules.required]"
                      label="Desconto* (%)"
                      validate-on-blur
                      type="text"
                      max="100.00"
                      min="0.00"
                      outlined
                      rounded
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="regra.condicoes[0].parcelamento"
                      v-mask="'##'"
                      dense
                      autocomplete="off"
                      :rules="[rules.required]"
                      label="Parcelamento*"
                      validate-on-blur
                      type="text"
                      max="12"
                      step="1"
                      outlined
                      rounded
                      @keyup="() => {
                        regra.condicoes[0].parcelamento = regra.condicoes[0].parcelamento ? (parseInt(regra.condicoes[0].parcelamento) < 12 ? regra.condicoes[0].parcelamento.replace(/[^0-9]/g, '') : '12') : ''
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <base-vuetify-money
                      v-model="regra.condicoes[0].vlr_parcela"
                      dense
                      autocomplete="off"
                      :rules="[rules.required]"
                      label="Parcela mínima*"
                      validate-on-blur
                      outlined
                      rounded
                    />
                  </v-col>
                </v-row>
                <v-row v-if="tipo === 'campanha'">
                  <v-col cols="12">
                    <v-checkbox
                      v-model="regra.condicoes[0].regra_tecnico"
                      :true-value="'1'"
                      class="mt-0"
                      hide-details
                      :false-value="'0'"
                      label="Permitir ao técnico manter desconto fora da regra"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card
              v-else-if="regra.tipo === 'volume'"
              outlined
              style="border-radius: 12px"
            >
              <v-container
                fluid
                class="mt-5"
              >
                <v-row
                  v-for="(condicao, indexCondicao) in regra.condicoes"
                  :key="condicao.id"
                >
                  <v-col
                    cols="12"
                    md="1"
                    style="text-align: center; font-weight: bold;"
                  >
                    {{ indexCondicao + 1 }}º
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="condicao.volume"
                      dense
                      autocomplete="off"
                      :rules="[rules.required, rules.gt(0), rules.volumes(condicao.volume, regra)]"
                      label="Volumes"
                      validate-on-blur
                      type="number"
                      minlength="1"
                      step="10"
                      outlined
                      rounded
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="condicao.desconto"
                      v-mask="'##.##'"
                      dense
                      autocomplete="off"
                      :rules="[rules.required]"
                      label="Desconto* (%)"
                      validate-on-blur
                      type="text"
                      max="100.00"
                      min="0.00"
                      outlined
                      rounded
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="condicao.parcelamento"
                      v-mask="'##'"
                      dense
                      autocomplete="off"
                      :rules="[rules.required]"
                      label="Parcelamento*"
                      validate-on-blur
                      type="text"
                      max="12"
                      step="1"
                      outlined
                      rounded
                      @keyup="() => {
                        condicao.parcelamento = condicao.parcelamento ? (parseInt(condicao.parcelamento) < 12 ? condicao.parcelamento.replace(/[^0-9]/g, '') : '12') : ''
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <base-vuetify-money
                      v-model="condicao.vlr_parcela"
                      dense
                      autocomplete="off"
                      :rules="[rules.required]"
                      label="Parcela mínima*"
                      validate-on-blur
                      step="1"
                      outlined
                      rounded
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-btn
                      v-if="regra.condicoes.length !== 1"
                      icon
                      small
                      class="text-none text-white"
                      color="error"
                      title="Exclui a condição da regra."
                      @click="REMOVE_CONDICAO({ regra, condicao })"
                    >
                      <v-icon>
                        mdi-minus-circle
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <span
              v-if="produtosVazios(regra) && submitted"
              style="color:red;"
              class="font-weight-light"
            >
              *Deve-se adicionar pelo menos um produto na regra
            </span>
            <span
              v-if="regra.produtos.length > 0 ? regra.produtos.some(produto => (!produtosValidos((typeof produto === 'object') ? produto.id : produto))) : false"
              style="color:red;"
              class="font-weight-light"
            >
              *Alguns produtos dessa regra estão cadastrados em outra promoção
            </span>
            <v-spacer />
            <v-btn
              small
              color="red darken-3"
              dark
              title="Exclui a regra inteira."
              rounded
              class="text-none text-white justify-end"
              @click="REMOVE_REGRA(regra)"
            >
              <v-icon small>
                mdi-trash-can-outline
              </v-icon>
              Excluir Regra
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item eager>
          <v-container>
            <v-row>
              <v-col
                cols="10"
                sm="10"
                md="7"
              >
                <v-autocomplete
                  v-model="escolheProdutos[indexRegra]"
                  :items="produtosDisponiveis(indexRegra)"
                  :rules="[!produtosVazios(regra)]"
                  :filter="filterProduto"
                  item-text="nome"
                  item-value="id"
                  return-object
                  multiple
                  deletable-chips
                  label="Selecionar produtos para serem adicionados*"
                  no-data-text="Não há produtos disponíveis"
                  clearable
                  autocomplete="off"
                  validate-on-blur
                  small-chips
                  outlined
                  rounded
                  dense
                  hide-details
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index < 5"
                      rounded
                      small
                      class="caption"
                    >
                      <span>
                        {{ item.nome }}
                      </span>
                    </v-chip>
                    <span
                      v-if="index === 5"
                      class="grey--text caption"
                    >
                      (+{{ escolheProdutos[indexRegra].length - 5 }} outros)
                    </span>
                  </template>
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.codigo }}</v-list-item-subtitle>
                      <span
                        v-if="data.item.disabled"
                        color="red"
                      ><b><i>Esse produto já está cadastrado em uma
                        campanha/oferta</i></b></span>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                md="1"
                class="d-flex"
              >
                <v-btn
                  x-small
                  color="success"
                  fab
                  title="Adiciona os produtos selecionados a regra."
                  class="text-none text-white mx-auto"
                  style="margin-top: 3px;"
                  @click="adicionaProduto(indexRegra, regra)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <span
                  v-if="produtosVazios(regra) && submitted"
                  style="color:red;"
                  class="font-weight-light"
                >
                  *Deve-se adicionar pelo menos um produto na regra
                </span>

                <v-text-field
                  v-else
                  v-model="search"
                  append-icon="mdi-magnify"
                  color="primary"
                  label="Pesquisar"
                  outlined
                  rounded
                  dense
                  elevation="0"
                  hide-details
                  clearable
                  clear-icon="mdi-close"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :items="regra.produtos"
            :headers="headers"
            :sort-by="['nome']"
            :sort-desc="[false]"
            :search="search"
            calculate-widths
            single-expand
            class="text-no-wrap"
            :height="$vuetify.breakpoint.xl ? '250' : ($vuetify.breakpoint.xs ? '' : '150')"
          >
            <template v-slot:[`item.alerta`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="unidadesIndisponiveis(item).length != 0"
                    small
                    v-bind="attrs"
                    color="yellow darken-3"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                  <v-icon
                    v-if="!produtosValidos((typeof item === 'object') ? item.id : item)"
                    small
                    v-bind="attrs"
                    color="red darken-3"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <div v-if="unidadesIndisponiveis(item).length != 0">
                  <span><b>Esse produto não está no catálogo das seguintes unidades:</b></span>
                  <span
                    v-for="unidade in unidadesIndisponiveis(item)"
                    :key="unidade.id"
                  ><br>{{
                    unidade.nome_fantasia
                  }}</span>
                </div>
                <div v-if="!produtosValidos((typeof item === 'object') ? item.id : item)">
                  Esse produto está cadastrado em outra promoção ativa e será removido ao salvar a oferta.
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                :key="actions.title"
                :color="actions.color"
                :title="actions.title"
                fab
                small
                class="mr-2"
                @click="actions.click(item)"
              >
                {{ actions.icon }}
              </v-icon>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item eager>
          <bonificacao-card
            ref="cardBonificacao"
            v-model="regra.bonificacoes"
            :produtos="[...regra.produtos, ...produtosBonificados()]"
            @exluirBonificacao="delBonificacao(indexBonificacao)"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-form>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
  import { REMOVE_REGRA, REMOVE_CONDICAO, NEW_CONDICAO, ADD_PRODUTOS, REMOVE_PRODUTO, SET_STATE, CLOSE } from '@/store/modules/forms/oferta'
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
  import Swal from 'sweetalert2'
  import BonificacaoCard from './BonificacaoCard.vue'

  export default {
    name: 'RegraCards',
    components: { BonificacaoCard },
    props: {
      unidade: {
        type: Array,
        default: () => [],
      },
      regra: {
        type: Object,
        default: () => {},
      },
      indexRegra: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        search: '',
        itemsCalculo: [{ value: 'Multiplos', text: 'Múltiplos' }, { value: 'Atingimento', text: 'Atingimento' }],
        escolheProdutos: [],
        tabs: 0,
        headers: [
          { text: '', value: 'actions', sortable: false, align: 'center', width: '50' },
          {
            text: '',
            value: 'alerta',
            sortable: false,
            align: 'start',
            width: '35',
          },
          { text: 'Código', align: 'center', sortable: true, value: 'codigo', width: '120', filterable: true },
          { text: 'Nome', value: 'nome', filterable: true, sortable: true, class: 'celula', cellClass: 'celula' },
          { text: 'Marca', value: 'marca' },
          { text: 'Fornecedor', value: 'fornecedor', filterable: true, sortable: true },

        ],
        actions: {
          title: 'Excluir Produto',
          color: 'red darken-3',
          click: item => this.REMOVE_PRODUTO({ regra: this.regra, item }),
          icon: 'mdi-trash-can-outline',
        },
      }
    },
    computed: {
      ...mapGetters('form/oferta', ['submitted', 'produtoDisponivel']),
      ...mapState('form/oferta', ['unidadesMedida', 'produtos', 'rules']),
      ...mapFields('form/oferta', ['produtosEscolhidos']),
      ...mapFields('form', [
        'data.nome',
        'data.unidades',
        'data.tipo',
        'data.id',
      ]),
      ...mapMultiRowFields('form', [
        'data.regras',
      ]),
    },
    methods: {
      ...mapMutations('form/oferta', [ADD_PRODUTOS, REMOVE_PRODUTO, NEW_CONDICAO, REMOVE_CONDICAO, SET_STATE, CLOSE]),
      ...mapActions('form/oferta', [REMOVE_REGRA]),
      validate () {
        if (this.produtosVazios(this.regra)) {
          this.$refs.regrasForm.validate()
          this.tabs = 1
          return false
        }
        if (!this.$refs.regrasForm.validate()) {
          return false
        }
        if (!this.$refs.cardBonificacao.validate()) {
          this.tabs = 2
          return false
        }
        return true
      },
      resetValidation () {
        return this.$refs.regrasForm.resetValidation()
      },
      produtosValidos (produto) {
        const unidades = this.unidade.map(und => und.id)
        const retorno = this.produtoDisponivel.filter((elemento) => {
          const retornoUnidade = elemento.unidades.filter(und => unidades.includes(und))
          const retornoProduto = elemento.produtos.includes(produto)
          return (retornoUnidade.length > 0 && retornoProduto)
        })
        return (retorno.length <= 0)
      },
      produtosBonificados () {
        return this.produtos.filter(produto => {
          return this.produtosValidos(typeof produto === 'object' ? produto.id : produto)
        })
      },
      produtosDisponiveis (nindex) {
        const regrasReversas = [...this.regras]
        let produtos_acadastrar = this.produtos.filter(produto =>
          !this.regras.some(regra =>
            regra.produtos.some(p => p.id === produto.id),
          ),
        )

        // Não mexa nesse filtro aqui--------------------------------------------------------------------------------------//
        produtos_acadastrar = produtos_acadastrar.filter(produto =>
          this.produtosValidos(typeof produto === 'object' ? produto.id : produto),
        )
        // ---------------------------------------------------------------------------------------------------------------//

        if (this.regras.length > 0 && regrasReversas[nindex].tipo === 'volume') {
          produtos_acadastrar = produtos_acadastrar.filter(produto =>
            produto.unidade_id === regrasReversas[nindex].unidade,
          )
        }

        // adicionar filtro de data
        return produtos_acadastrar.length > 0 ? produtos_acadastrar : this.produtos
      },
      produtosVazios (regra) {
        return (regra.produtos.length === 0)
      },
      information (tipo) {
        let mensagem = ''

        if (tipo === 'quantidade') {
          mensagem = 'A regra definida em unidades fará com que a plataforma trate cada produto como sendo uma unidade, de forma a contar quantas unidades para ver se atingiu as condições presente na oferta/campanha.'
        } else if (tipo === 'volume') {
          mensagem = 'A regra definida em volumes a plataforma irá analisar o volume de cada produto para ver se atingiu as condições presente na oferta/camapanha'
        }

        Swal.fire({
          title: 'Explicação',
          text: mensagem,
          icon: 'question',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      close () {
        this.SET_STATE({ submitted: false })
        this.CLOSE()
      },
      unidadesIndisponiveis (produto) {
        const unidades_indisponiveis = this.unidades.filter(x1 => produto.unidades.filter(x2 => (typeof x2 === 'object') ? (x2.id === x1.id) : (x2 === x1.id)).length === 0)
        return unidades_indisponiveis
      },
      adicionaProduto (index, regra) {
        this.produtosEscolhidos = this.escolheProdutos[index]
        this.ADD_PRODUTOS(regra)
        this.escolheProdutos[index] = []
      },
      unidadeChange () {
        const produtosParaRemover = this.regra.produtos.filter(produto =>
          produto.unidade_id !== this.regra.unidade,
        )
        produtosParaRemover.forEach(item => this.REMOVE_PRODUTO({ regra: this.regra, item }))
      },
      delBonificacao (index) {
        this.regra.bonificacoes.splice(index, 1)
      },
      validateBonificacao () {
        let valido = true
        if (!this.$refs.cardBonificacao) {
          return valido
        }
        this.$refs.cardBonificacao.forEach(item => {
          if (!item.validate()) {
            valido = false
          }
        })
        return valido
      },
      filterProduto (item, consulta, nome) {
        if (nome.toLowerCase().includes(consulta.toLowerCase())) return true
        if (item.codigo.includes(consulta)) return true
        return false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .celula {
    min-width: 800px;
  }

</style>
